import { graphql } from 'gatsby'
import React from 'react'
import Lottie from 'react-lottie'

import errorAnimation from '../assets/lotties/404'
import '../styles/global.css'
import { Button } from '../components/common/button'
import { CookiesBox } from '../components/cookies-box'
import { Footer } from '../components/footer'
import { Navigation } from '../components/navigation'
import { SEO } from '../components/seo'

const ErrorPage = () => (
  <>
    <SEO title="Page not found" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <main className="h-screen bg-blue-gradient">
        <div className="h-full container mx-auto">
          <div className="h-full flex flex-col justify-start items-center pb-20 text-white">
            <div>
              <Lottie options={{ animationData: errorAnimation }} />
            </div>
            <p className="pt-8 pb-14 text-xl">
              Oops! It seems something went wrong
            </p>
            <div className="flex justify-center">
              <Button color="green" size="md" title="Go to homepage" to="/" />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default ErrorPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
